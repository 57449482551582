$(document).ready(function() {

    // Slick slider banner
    $('.banner__slider').slick({
        autoplay: true,
        autoplaySpeed: 7000,
        draggable: true,
        arrows: false,
        dots: false,
        fade: true,
        speed: 0,
        pauseOnFocus: false,
        pauseOnHover: false,
        infinite: true,
    });

});
